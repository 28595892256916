import * as i0 from '@angular/core';
import { inject, Injectable, TemplateRef, ViewContainerRef, Directive, Input, APP_INITIALIZER, NgModule, Pipe } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CrmConfigService, CrmModuleConfigToken } from 'common-module/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
var CrmDisplayViewMode;
(function (CrmDisplayViewMode) {
  CrmDisplayViewMode["XS"] = "xs";
  CrmDisplayViewMode["SM"] = "sm";
  CrmDisplayViewMode["MD"] = "md";
  CrmDisplayViewMode["LG"] = "lg";
  CrmDisplayViewMode["XL"] = "xl";
  CrmDisplayViewMode["XXL"] = "xxl";
})(CrmDisplayViewMode || (CrmDisplayViewMode = {}));
const crmDisplayViewModeOrder = [CrmDisplayViewMode.XS, CrmDisplayViewMode.SM, CrmDisplayViewMode.MD, CrmDisplayViewMode.LG, CrmDisplayViewMode.XL, CrmDisplayViewMode.XXL];
const defaultDisplayConfiguration = {
  breakpoints: {
    [CrmDisplayViewMode.XS]: '(max-width: 575px)',
    [CrmDisplayViewMode.SM]: '(min-width: 576px) and (max-width: 767px)',
    [CrmDisplayViewMode.MD]: '(min-width: 768px) and (max-width: 991px)',
    [CrmDisplayViewMode.LG]: '(min-width: 992px) and (max-width: 1199px)',
    [CrmDisplayViewMode.XL]: '(min-width: 1200px) and (max-width: 1599px)',
    [CrmDisplayViewMode.XXL]: '(min-width: 1600px)'
  }
};
class CrmDisplayService {
  constructor() {
    this.configService = inject(CrmConfigService);
    this.observer = inject(BreakpointObserver);
    this.handler$ = new Subject();
    this._current$ = new BehaviorSubject(CrmDisplayViewMode.XXL);
    this._view$ = new BehaviorSubject({
      previous: null,
      current: CrmDisplayViewMode.XXL
    });
    this._resize$ = new BehaviorSubject({
      width: window.innerWidth
    });
    this.config = this.configService.mergeConfig(defaultDisplayConfiguration, 'modules.display');
    this.handler$.pipe(map(state => this.resolveCurrentView(state))).subscribe(this._current$);
    this._current$.pipe(map(current => this.resolveView(current))).subscribe(this._view$);
    this.observer.observe(Object.values(this.config.breakpoints)).subscribe(this.handler$);
    fromEvent(window, 'resize').pipe(map(() => ({
      width: window.innerWidth
    }))).subscribe(this._resize$);
  }
  get view() {
    return this._view$.value;
  }
  get view$() {
    return this._view$.asObservable();
  }
  get resize$() {
    return this._resize$.asObservable();
  }
  isView(view) {
    return this._current$.value === view;
  }
  isMinimalView(view, current = this._current$.value ?? CrmDisplayViewMode.XXL) {
    const sliceOfLargerViews = crmDisplayViewModeOrder.slice(crmDisplayViewModeOrder.findIndex(value => value === view));
    return sliceOfLargerViews.includes(current);
  }
  isMaximalView(view, current = this._current$.value ?? CrmDisplayViewMode.XS) {
    const sliceOfSmallerViews = crmDisplayViewModeOrder.slice(0, crmDisplayViewModeOrder.findIndex(value => value === view) + 1);
    return sliceOfSmallerViews.includes(current);
  }
  watchView(view) {
    return this._current$.pipe(map(current => current === view), distinctUntilChanged());
  }
  watchMinimalView(view) {
    return this._current$.pipe(map(current => this.isMinimalView(view, current)), distinctUntilChanged());
  }
  watchMaximalView(view) {
    return this._current$.pipe(map(current => this.isMaximalView(view, current)), distinctUntilChanged());
  }
  /**
   * Resolve current view from breakpoint state
   *
   * @param state
   * @private
   */
  resolveCurrentView(state) {
    if (!state.matches) {
      return CrmDisplayViewMode.XXL;
    }
    const breakpoint = Object.entries(this.config.breakpoints).find(([_, media]) => state.breakpoints[media]);
    if (!breakpoint?.[0]) {
      return CrmDisplayViewMode.XXL;
    }
    return breakpoint[0];
  }
  /**
   * Resolve view with current and previous views
   *
   * @param current
   * @private
   */
  resolveView(current) {
    return {
      previous: this._view$.value?.previous ?? null,
      current
    };
  }
  static {
    this.ɵfac = function CrmDisplayService_Factory(t) {
      return new (t || CrmDisplayService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmDisplayService,
      factory: CrmDisplayService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmDisplayService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class CrmMinimalViewDirective {
  constructor() {
    this.viewTemplate = inject(TemplateRef);
    this.viewContainer = inject(ViewContainerRef);
    this.displayService = inject(CrmDisplayService);
  }
  set crmMinimalView(key) {
    if (this.displayService.isMinimalView(key)) {
      this.viewContainer.createEmbeddedView(this.viewTemplate);
    } else {
      this.viewContainer.clear();
    }
  }
  static {
    this.ɵfac = function CrmMinimalViewDirective_Factory(t) {
      return new (t || CrmMinimalViewDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmMinimalViewDirective,
      selectors: [["", "crmMinimalView", ""]],
      inputs: {
        crmMinimalView: "crmMinimalView"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmMinimalViewDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[crmMinimalView]'
    }]
  }], null, {
    crmMinimalView: [{
      type: Input
    }]
  });
})();
class CrmMaximalViewDirective {
  constructor() {
    this.viewTemplate = inject(TemplateRef);
    this.viewContainer = inject(ViewContainerRef);
    this.displayService = inject(CrmDisplayService);
  }
  set crmMaximalView(key) {
    if (this.displayService.isMaximalView(key)) {
      this.viewContainer.createEmbeddedView(this.viewTemplate);
    } else {
      this.viewContainer.clear();
    }
  }
  static {
    this.ɵfac = function CrmMaximalViewDirective_Factory(t) {
      return new (t || CrmMaximalViewDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmMaximalViewDirective,
      selectors: [["", "crmMaximalView", ""]],
      inputs: {
        crmMaximalView: "crmMaximalView"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmMaximalViewDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[crmMaximalView]'
    }]
  }], null, {
    crmMaximalView: [{
      type: Input
    }]
  });
})();
class CrmDisplayModule {
  static forRoot(config) {
    return {
      ngModule: CrmDisplayModule,
      providers: [{
        provide: CrmModuleConfigToken,
        multi: true,
        useValue: {
          display: config
        }
      }, {
        provide: APP_INITIALIZER,
        multi: true,
        deps: [CrmDisplayService],
        useFactory: _ => () => {}
      }]
    };
  }
  static {
    this.ɵfac = function CrmDisplayModule_Factory(t) {
      return new (t || CrmDisplayModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmDisplayModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmDisplayModule, [{
    type: NgModule
  }], null, null);
})();
class CrmIsViewPipe {
  constructor() {
    this.displayService = inject(CrmDisplayService);
  }
  transform(value) {
    return this.displayService.isView(value);
  }
  static {
    this.ɵfac = function CrmIsViewPipe_Factory(t) {
      return new (t || CrmIsViewPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsView",
      type: CrmIsViewPipe,
      pure: false,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsViewPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsView',
      pure: false
    }]
  }], null, null);
})();
class CrmIsMinimalViewPipe {
  constructor() {
    this.displayService = inject(CrmDisplayService);
  }
  transform(value) {
    return this.displayService.isMinimalView(value);
  }
  static {
    this.ɵfac = function CrmIsMinimalViewPipe_Factory(t) {
      return new (t || CrmIsMinimalViewPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsMinimalView",
      type: CrmIsMinimalViewPipe,
      pure: false,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsMinimalViewPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsMinimalView',
      pure: false
    }]
  }], null, null);
})();
class CrmIsMaximalViewPipe {
  constructor() {
    this.displayService = inject(CrmDisplayService);
  }
  transform(value) {
    return this.displayService.isMaximalView(value);
  }
  static {
    this.ɵfac = function CrmIsMaximalViewPipe_Factory(t) {
      return new (t || CrmIsMaximalViewPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmIsMaximalView",
      type: CrmIsMaximalViewPipe,
      pure: false,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmIsMaximalViewPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmIsMaximalView',
      pure: false
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmDisplayModule, CrmDisplayService, CrmDisplayViewMode, CrmIsMaximalViewPipe, CrmIsMinimalViewPipe, CrmIsViewPipe, CrmMaximalViewDirective, CrmMinimalViewDirective, crmDisplayViewModeOrder, defaultDisplayConfiguration };
